import { bannerCarouselOne, bannerCarouselMultiple, pageSlider, teamCarousel } from '@/modules/carousel.js';

window.S1ProApp = window.S1ProApp || {};

$(document).ready(function () {
    S1ProApp.bannerCarouselOne = bannerCarouselOne;
    S1ProApp.bannerCarouselMultiple = bannerCarouselMultiple;
    S1ProApp.pageSlider = pageSlider;
    S1ProApp.teamCarousel = teamCarousel;

    bannerCarouselOne();
    bannerCarouselMultiple();
    pageSlider();
    teamCarousel();
});