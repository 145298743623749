// js dependencies
import '@popperjs/core';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all.js';

import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'flatpickr/dist/flatpickr.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

import '../css/style.css';

import { acceptCookieMessage } from './modules/cookies.js';

window.S1ProApp = window.S1ProApp || {};

$(document).ready(function () {
    S1ProApp.acceptCookieMessage = acceptCookieMessage;
});