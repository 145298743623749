﻿import 'slick-carousel';

export function bannerCarouselOne() {
    $('.banner-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: false,
        pauseOnHover: true,
        speed: 600,
        arrows: true,
        prevArrow: '<button type="button" class="carousel-control left" aria-label="carousel-control"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="carousel-control right" aria-label="carousel-control"><i class="fas fa-chevron-right"></i></button>'
    }).removeClass('invisible').addClass('visible');
}

export function bannerCarouselMultiple() {
    $('.banner-carousel.banner-carousel-2').slick({
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        dots: false,
        speed: 1250,
        arrows: true,
        prevArrow: '<button type="button" class="carousel-control left" aria-label="carousel-control"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="carousel-control right" aria-label="carousel-control"><i class="fas fa-chevron-right"></i></button>'
    });
}

export function pageSlider() {
    $('.page-slider').slick({
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        speed: 600,
        arrows: false,
        prevArrow: '<button type="button" class="carousel-control left" aria-label="carousel-control"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="carousel-control right" aria-label="carousel-control"><i class="fas fa-chevron-right"></i></button>'
    }).removeClass('invisible').addClass('visible');
}

export function teamCarousel({
        elementName = 'default-carousel',
        slidesToShow = 1,
        dots = true,
        arrows = false,
        speed = 300
    } = {}) {
    const $teamSlide = $(`.${elementName}`);
    
    if ($teamSlide.length === 0) {
        console.error(`teamCarousel: Element .${elementName} not found`);
        return;
    }

    const imageCount = $teamSlide.children().length;

    if (imageCount <= 1) {
        initializeSingleSlide($teamSlide, dots, speed);
    } else {
        initializeMultipleSlides($teamSlide, slidesToShow, dots, arrows, speed);
    }
}

function initializeSingleSlide($teamSlide, dots, speed) {
    $teamSlide.slick({
        dots: dots,
        infinite: false,
        speed: speed,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });

    // Center the single image
    $teamSlide.css({
        display: 'flex',
        justifyContent: 'center'
    });
    $teamSlide.children().css({
        margin: '0 auto'
    });
}

function initializeMultipleSlides($teamSlide, slidesToShow, dots, arrows, speed) {
    $teamSlide.slick({
        dots: dots,
        infinite: false,
        speed: speed,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        arrows: arrows,
        prevArrow: '<button type="button" class="carousel-control left" aria-label="carousel-control"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="carousel-control right" aria-label="carousel-control"><i class="fas fa-chevron-right"></i></button>',
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: slidesToShow > 3 ? 3 : slidesToShow,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: slidesToShow > 2 ? 2 : slidesToShow,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 481,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });
}

